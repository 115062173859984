
/**
 * @description
 * 랭킹 아이템 > 썸네일
 */
import Thumbnail from '@/components/common/img/Thumbnail';

export default {
  name: 'RankingThumbnail',
  components: { Thumbnail },
  props: {
    id: { type: Number, required: true, default: 0 },
    thumbnailImage: { type: String, required: true, default: null },
    isFull: { type: Boolean, default: false },
    styleClass: {
      type: String,
      default: 'PAGE',
      validator: v => ['HOME', 'PAGE'].includes(v),
    },
  },
};
