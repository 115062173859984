
/**
 * @description
 * 랭킹 아이템 > 랭크와 이전대비 상승/하락폭 표기
 */
import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'Rank',
  components: { Icon },
  props: {
    rankChangeValue: { type: Number, required: true, default: 0 },
    rankChangeType: { type: String, required: true, default: null }, // UP | DOWN | MAINTAIN | NEW
    rank: { type: Number, required: true, default: 0 }, // api응답에 넘어온 순서 index + 1 한 값
  },
  data() {
    return {
      first: require('@/assets/img/ranking/medal-gold.svg'),
      second: require('@/assets/img/ranking/medal-silver.svg'),
      third: require('@/assets/img/ranking/medal-bronze.svg'),
      medalRankImg: null,
    };
  },
  mounted() {
    this.medalRankImg = this.getMedalImg(this.rank);
  },
  methods: {
    getMedalImg(rank) {
      switch (rank) {
        case 3:
          return this.third;
        case 2:
          return this.second;
        case 1:
          return this.first;
        default:
          return null;
      }
    },
  },
};
