
/**
 * @description
 * 홈 화면 랭킹 > 큐레이션 모바일 버전
 */

import {
  mobileNextButtonClass,
  mobilePrevButtonClass,
} from '@/utils/ranking/homeRankingUtils.js';

import RankingList from '@/components/new-home/common/ranking/RankingList.vue';
import RankingMoreButton from '@/components/new-home/common/ranking/RankingMoreButton.vue';
import { Swiper } from 'vue-awesome-swiper';
import MobileRankingSwiperNavigation from './MobileRankingSwiperNavigation.vue';

export default {
  name: 'RankingCurationMobile',
  components: {
    Swiper,
    RankingList,
    RankingMoreButton,
    MobileRankingSwiperNavigation,
  },
  props: {
    rankingClassList: { type: Array, required: true, default: () => [] },
  },
  data() {
    return {
      mobileRankingCurationRef: null,
      rankingCurationOptions: {
        slidesPerView: 'auto',
        grabCursor: true,
        effect: 'slide',
        mouseWheel: false,
        spaceBetween: 24,
        navigation: {
          nextEl: `.${mobileNextButtonClass}`,
          prevEl: `.${mobilePrevButtonClass}`,
        },
      },
    };
  },
  computed: {
    rankingList: ({ rankingClassList }) => [
      rankingClassList.slice(0, 5),
      rankingClassList.slice(5, 10),
      rankingClassList.slice(10, 15),
      rankingClassList.slice(15),
    ],
    activeIndex: ({ mobileRankingCurationRef }) =>
      mobileRankingCurationRef?.activeIndex || 0,
  },
  watch: {
    rankingClassList() {
      this.mobileRankingCurationRef && this.mobileRankingCurationRef.slideTo(0);
    },
  },
  mounted() {
    // swiper ref에 바인딩
    this.mobileRankingCurationRef =
      this.$refs.mobileRankingCurationRef?.$swiper;
  },
  methods: {
    logClickEvent(rankingItem) {
      this.$emit('log-click-event', rankingItem);
    },
  },
};
