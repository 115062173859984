
/**
 * @description
 * 홈 랭킹 > 랭킹 큐레이션의 리스트
 */

import { SwiperSlide } from 'vue-awesome-swiper';

import HomeRankingItem from '@/components/ranking/rankingItem/HomeRankingItem.vue';

export default {
  name: 'RankingList',
  components: { SwiperSlide, HomeRankingItem },
  props: {
    classList: { type: Array, default: () => [] },
  },
  methods: {
    logClickEvent(rankingItem) {
      this.$emit('log-click-event', rankingItem);
    },
  },
};
