/**
 * @description
 * 홈 화면 랭킹 관련 유틸리티와 enums 로직 모은 파일
 */

// 홈 화면 랭킹은 주간 랭킹만 사용합니다.
const defaultPeriodType = 'WEEKLY';

/**
 * @description
 * 학습 유형에 따라 다른 필터값을 내려줍니다.
 */
export const getHomeRankingTabList = learningType => [
  {
    text: '급상승',
    value: 'TRENDING',
    description: '전주 대비 수업신청이 가장 많이 상승한 수업이에요.',
    classList: [],
    query: {
      learningType,
      periodType: defaultPeriodType,
      majorFilterType: 'POPULARITY',
      subFilterType: 'TRENDING',
    },
  },
  {
    text: '베스트',
    value: 'BEST',
    description: '지난 한 주간 가장 인기 있었던 수업이에요.',
    classList: [],
    query: {
      learningType,
      periodType: defaultPeriodType,
      majorFilterType: 'POPULARITY',
      subFilterType: 'BEST',
    },
  },
  {
    text: '신규 수업',
    value: 'NEW_RELEASE',
    description: '새로 개설된 수업 중 가장 인기가 많은 수업이에요.',
    classList: [],
    query: {
      learningType,
      periodType: defaultPeriodType,
      majorFilterType: 'POPULARITY',
      subFilterType: 'NEW_RELEASE',
    },
  },
  {
    text: '원데이 수업',
    value: 'ONEDAY',
    description: '지난 한 주간 가장 인기 있었던 원데이 수업이에요.',
    classList: [],
    query: {
      learningType,
      periodType: defaultPeriodType,
      majorFilterType: 'CLASS_TYPE',
      subFilterType: 'ONEDAY',
    },
  },
];

// 랭킹 큐레이션의 좌우 이동 class값을 버튼에도 주고 swiper에 바인딩하기 위해 변수로 빼서 사용
export const desktopPrevButtonClass = 'desktop-prev-button';
export const desktopNextButtonClass = 'desktop-next-button';
export const mobilePrevButtonClass = 'mobile-prev-button';
export const mobileNextButtonClass = 'mobile-next-button';
