
/**
 * @description
 * 홈 랭킹 큐레이션 > 모바일 > 큐레이션 하단에 붙는 네비게이션 버튼과 페이지네이션
 */
import {
  mobileNextButtonClass,
  mobilePrevButtonClass,
} from '@/utils/ranking/homeRankingUtils.js';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'MobileRankingSwiperNavigation',
  components: { Icon },
  props: {
    slideLength: { type: Number, default: 0 },
    activeIndex: { type: Number, default: 0 },
  },
  data: () => ({
    mobilePrevButtonClass,
    mobileNextButtonClass,
  }),
};
