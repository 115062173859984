
/**
 * @description
 * 랭킹 아이템 > 타이틀
 */
export default {
  name: 'RankingTitle',
  props: {
    title: { type: String, required: true, default: null },
  },
};
