
/**
 * @description
 * 홈 화면 인기급상승에 쓰이는 랭킹 컴포넌트
 */

import CommonProfile from '@/components/common/CommonProfile.vue';
import Rank from './common/Rank.vue';
import RankingThumbnail from './common/RankingThumbnail.vue';
import RankingTitle from './common/RankingTitle.vue';

export default {
  name: 'HomeRankingItem',
  components: {
    Rank,
    RankingThumbnail,
    RankingTitle,
    CommonProfile,
  },
  props: {
    id: { type: Number, required: true },
    title: { type: String, default: null },
    rankChangeValue: { type: Number, default: 0 }, // 랭크 변동 폭
    rankChangeType: { type: String, default: null }, // 랭크가 어느 방향으로 이동했는지 (UP | DOWN | MAINTAIN)
    rank: { type: Number, default: 0 }, // api응답에 넘어온 순서 index + 1 한 값
    thumbnailImage: { type: String, default: null },
    classType: { type: String, required: true, default: null },
    teacherName: { type: String, default: null },
    profileImage: { type: String, default: null },
    ratingCount: { type: Number, default: 0 },
    ratingAvg: { type: Number, default: 0 },
    favoriteCount: { type: Number, default: 0 },
    isFull: { type: Boolean, default: false },
  },
  data() {
    return {
      isPending: false,
    };
  },
};
