
/**
 * @description
 * 랭킹 큐레이션 > 페이지네이션 마지막 더보기 버튼
 */

import 'swiper/css/swiper.css';
import { SwiperSlide } from 'vue-awesome-swiper';

import Icon from '@/components/common/icon/Icon.vue';

export default {
  name: 'RankingMoreButton',
  components: { SwiperSlide, Icon },
  methods: {
    handleClick() {
      this.$emit('handle-click');
    },
  },
};
